//
//	Modal
//

.modal-content {
	border-radius: 16px;
	border: none;
	@include dark-mode {
		background-color: #0c111d;
	}
}

.modal {
	&-backdrop {
		@include backdrop-filter-blur($modal-backdrop--blur);

		background-color: $modal-backdrop-bg;

		&.show {
			opacity: 0.5;
		}
	}
}
